import { useContext, useState } from "react";
import { useBiometrics } from "../../../../hooks/useBiometrics";
import { ParticipantContextProps } from "../../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../../contexts/Participant.context";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import BiometricsChartsChart from "./BiometricsChartsChart";
import BiometricsChartsLinks from "./BiometricsChartsLinks";
import BiometricsChartsHistograms from "./BiometricsChartsHistograms";
import {
	CHART_DATA,
	CHART_FILTER,
	CHART_TYPE,
} from "../../../../models/pages/biometrics/biometrics-charts.model";
import BiometricsChartsFilters from "./BiometricsChartsFilters";

const BiometricsCharts = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const biometrics = useBiometrics({
		participantId: participantContext.participant!.id,
	});

	const [chartFilters, setChartFilters] = useState<{ type: CHART_TYPE; range: CHART_FILTER }>({
		type: CHART_TYPE.GLUCOSE,
		range: CHART_FILTER.MONTH,
	});

	const [chartDatasets, setChartDatasets] = useState<CHART_DATA | undefined>();

	return (
		<div className="biometrics-charts">
			{biometrics.isLoading || biometrics.isError ? (
				<UiFieldMessage
					severity={
						biometrics.isLoading
							? FieldMessageSeverity.LOADING
							: FieldMessageSeverity.DANGER
					}
					label={
						biometrics.isLoading
							? "PARTICIPANT.BIOMETRICS.MESSAGES.LOADING"
							: "PARTICIPANT.BIOMETRICS.MESSAGES.ERROR"
					}
				/>
			) : (
				<>
					<BiometricsChartsFilters
						filters={chartFilters}
						onFiltersChange={setChartFilters}
					/>

					<BiometricsChartsChart
						biometrics={biometrics.data ?? new Map()}
						filters={chartFilters}
						onDatasetsChange={setChartDatasets}
					/>

					<BiometricsChartsHistograms
						participantId={participantContext.participant!.id}
						range={chartFilters.range}
						datasets={chartDatasets}
					/>

					<BiometricsChartsLinks kannactId={participantContext.participant!.kannactId} />
				</>
			)}
		</div>
	);
};

export default BiometricsCharts;
