import { useTranslation } from "react-i18next";
import UiFieldMessage from "../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../models/components/field-message.model";
import {
	CHART_DATA,
	CHART_FILTER,
} from "../../../../models/pages/biometrics/biometrics-charts.model";
import { useEffect, useState } from "react";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { BiometricsHelper } from "../../../../services/biometrics/biometrics-helper.service";

const BiometricsChartsHistograms = ({
	participantId,
	range,
	datasets,
}: {
	participantId: number;
	range: CHART_FILTER;
	datasets: CHART_DATA | undefined;
}) => {
	const { t } = useTranslation("common");

	const [handleState, setHandleState] = useState<{
		data: string | undefined;
		isLoading: boolean;
		isError: boolean;
	}>({
		data: undefined,
		isLoading: false,
		isError: false,
	});

	const getData = async () => {
		setHandleState({ data: undefined, isLoading: true, isError: false });
		await EndpointsService.dataRetriever
			.getGlucoseHistograms({
				config: {
					responseType: "blob",
					params: {
						participantId,
						startDate: BiometricsHelper.setStartDate(range),
						endDate: new Date().toISOString(),
					},
				},
			})
			.then((response) => {
				setHandleState({
					data: URL.createObjectURL(response),
					isLoading: false,
					isError: false,
				});
			})
			.catch(() => setHandleState({ data: undefined, isLoading: false, isError: true }));
	};

	useEffect(() => {
		if (!BiometricsHelper.emptyDataset(datasets)) {
			getData();
		}
	}, [range, datasets]);

	return (
		<div className="">
			<div className="text-bold fs-5 mt-2">
				{t("PARTICIPANT.BIOMETRICS.CHARTS.TITLE_HISTOGRAMS")}
			</div>
			{handleState.isLoading ||
			handleState.isError ||
			BiometricsHelper.emptyDataset(datasets) ? (
				<div className="k-empty-message mt-2">
					<UiFieldMessage
						severity={
							handleState.isLoading
								? FieldMessageSeverity.LOADING
								: handleState.isError
									? FieldMessageSeverity.DANGER
									: FieldMessageSeverity.INFO
						}
						label={
							handleState.isLoading
								? "PARTICIPANT.BIOMETRICS.MESSAGES.LOADING_HISTOGRAMS"
								: handleState.isError
									? "PARTICIPANT.BIOMETRICS.MESSAGES.ERROR"
									: "PARTICIPANT.BIOMETRICS.MESSAGES.NO_DATA_HISTOGRAMS"
						}
					/>
				</div>
			) : (
				<div className="">
					<img
						src={handleState.data}
						height="400px"
						width="auto"
						alt="histograms chart"
					/>
				</div>
			)}
		</div>
	);
};
export default BiometricsChartsHistograms;
